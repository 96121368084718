import { render, staticRenderFns } from "./Personel.vue?vue&type=template&id=eef2bade&scoped=true&"
import script from "./Personel.vue?vue&type=script&lang=js&"
export * from "./Personel.vue?vue&type=script&lang=js&"
import style0 from "./Personel.vue?vue&type=style&index=0&id=eef2bade&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eef2bade",
  null
  
)

export default component.exports