<template>
  <b-container class="mt-4">
    <b-form @submit="filterPersonel">
      <b-card header-tag="header" header-class="header">
        <template #header>
          <label v-text="'Personeller'" class="mr-2 text-white"></label>
          <b-button variant="primary" to="/addPersonel">Personel ekle</b-button>
        </template>
        <b-row>
          <b-col>
            <b-form-input
              placeholder="TC / Ad-Soyad"
              v-model="personelFilter.where"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-group label-for="table-select-mode-select">
              <b-form-select
                id="table-select-mode-select"
                v-model="personelFilter.region"
                :options="regionOptions"
                class="selection-field"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label-for="table-select-mode-select">
              <b-form-select
                id="table-select-mode-select"
                v-model="personelFilter.role"
                :options="roleOptions"
                class="selection-field"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button variant="secondary" type="submit"> Gönder </b-button>
          </b-col>
        </b-row>
        <hr />
        <PMSTable
          :tableFields="getPersonelFields"
          :tableItems="getPersonel"
          :pageCount="getPersonelTotalPageCount"
          :editable="true"
          deleteMessage="Personeli silmek istediğinizden emin misiniz? Personelin katıldığı yarışma grupları da silinecektir!"
          :erasable="true"
          :actionModes="['Sil', 'Onayla']"
          :nameWithSurname="true"
          @openDetail="openDetail($event)"
          @editClick="editClick($event)"
          @onPageChanged="onPageChanged"
          @multipleDeleteAction="deleteMultiple($event)"
          @multipleApproveAction="approveMultiplePersonel"
        ></PMSTable>
      </b-card>
    </b-form>
  </b-container>
</template>

<script>
import PMSTable from "@/components/tables/GeneralTable.vue";
import roleOptions from "@/store/constants/roleOptions.js";
import cities from "@/store/constants/cities";
import { mapGetters } from "vuex";
export default {
  components: {
    PMSTable,
  },
  computed: {
    ...mapGetters([
      "getPersonel",
      "getPersonelFields",
      "getPersonelTotalPageCount",
      "getPersonelFilter",
    ]),
    personelFilter: {
      get() {
        return this.getPersonelFilter;
      },
      set(value) {
        this.$store.commit("updatePersonelFilter", value);
      },
    },
  },
  mounted() {
    this.$store.dispatch(
      "initPersonel",
      this.$router.history.current.query.page
    );
    this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
  },
  data() {
    return {
      regionOptions: cities,
      roleOptions: roleOptions,
    };
  },
  methods: {
    onPageChanged(value) {
      this.$store.dispatch("initPersonel", value);
    },
    editClick(index) {
      this.$router.push({
        name: "EditPersonel",
        params: { id: this.getPersonel[index].id },
      });
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    openDetail(index) {
      this.$router.push({
        name: "PersonelDetail",
        params: { id: this.getPersonel[index].id },
      });
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    deleteMultiple(indexes) {
      this.$store.dispatch("deletePersonel", indexes);
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    approveMultiplePersonel(indexes) {
      this.$store.dispatch("approvePersonel", indexes);
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    filterPersonel(event) {
      event.preventDefault();
      this.$store.dispatch("initPersonel");
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>